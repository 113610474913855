
<div style="width: 100%; text-align:center; margin-top: 30px;">
  <h1>Trépico</h1>
</div>

<div class="contenedorDoble">
  <div class="box-container">
    <a href="https://www.trepico.com.gt" target="_blank">
      <div class="container">
        <img src="assets/img/Proyectos/Trepico.png"/>
      </div>
    </a>
  </div>
  <div class="box-container">
    <div class="content-container secondContainer">
      <a href="https://play.google.com/store/apps/details?id=com.Trepico.Clients" target="_blank">
        <img src="assets/img/Proyectos/AppAndroid.png" />
      </a>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <a href="https://apps.apple.com/gt/app/tr%C3%A9pico/id1554216207?l=en" target="_blank">
        <img src="assets/img/Proyectos/AppiOS.png" />
      </a>
    </div>
  </div>
</div>

<div style="width: 100%; text-align:center; margin-top: 50px;">
  <h1>Galileo Aid Drone</h1>
</div>

<div class="contenedorDoble" style="margin-bottom: 150px;">
  <div class="box-container">
    <a href="https://ieeexplore.ieee.org/document/9382777" target="_blank">
      <div class="container">
        <img src="assets/img/Proyectos/IEEE.png" style=" width:300px; height: 300px;" alt="Web Page" />
      </div>
    </a>
  </div>
</div>

