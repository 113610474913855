<nav>
  <a onclick="li_clicked()" routerLink="/">
    <img src="/assets/img/Yo.jpg" />
  </a>
  <div id="titulo">
    <h1>
      Rodrigo Cordero
    </h1>
    <h2>
      Mechatronic Engineer
    </h2>
  </div>

  <ul id="ListaMenu">
    <li onclick="li_clicked()"> <a routerLink="/certificaciones"> Certificaciones </a> </li>
    <li onclick="li_clicked()"> <a routerLink="/idiomas"> Idiomas </a> </li>
    <li onclick="li_clicked()"> <a routerLink="/deportes"> Deportes </a> </li>
    <li onclick="li_clicked()"> <a href="https://archivos.corderobot.com/Docs/CV_RodrigoCordero.pdf" target="_blank"> Currículum </a> </li>
    <li onclick="li_clicked()"> <a routerLink="/proyectos"> Proyectos </a> </li>
    <li onclick="li_clicked()"> <a routerLink="/contacto"> Contacto </a> </li>
  </ul>

  <img src="https://trepico.com.gt/images/icons/Hamburguesa.png" class="hamburguesa" id="navHamburguesa" onclick="desplegarMenu()">

</nav>

<router-outlet></router-outlet>

