<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <a href="mailto:rodrigo@ucordero.com.gt" target="_blank"><img src="/assets/img/Contactos/email.png" /></a>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <a href="https://www.instagram.com/corderobot/" target="_blank"><img src="/assets/img/Contactos/insta.png" /></a>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <a href="https://github.com/corderobot" target="_blank"><img src="/assets/img/Contactos/github.png" /></a>
    </div>
  </div>
</div>

<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <a href="https://www.linkedin.com/in/corderobot/" target="_blank"><img src="/assets/img/Contactos/linkedin.png" /></a>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <a href="https://www.facebook.com/corderobot/" target="_blank"><img src="/assets/img/Contactos/facebook.svg" /></a>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <a href="https://dev.to/corderobot" target="_blank"><img src="/assets/img/Contactos/dev.png" /></a>
    </div>
  </div>
</div>


<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <h3>contactar@corderobot.com</h3>
    </div>
  </div>
</div>



