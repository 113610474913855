import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CertificacionesComponent } from './certificaciones/certificaciones.component';
import { IdiomasComponent } from './idiomas/idiomas.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { DeportesComponent } from './deportes/deportes.component';
import { KnowledgesComponent } from './knowledges/knowledges.component';
import { ContactosComponent } from './contactos/contactos.component';

const routes: Routes = [
  { path: '', component: KnowledgesComponent },
  { path: 'certificaciones', component: CertificacionesComponent },
  { path: 'idiomas', component: IdiomasComponent },
  { path: 'proyectos', component: ProyectosComponent },
  { path: 'deportes', component: DeportesComponent },
  { path: 'contacto', component: ContactosComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
