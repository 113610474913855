<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <div class="swiper-container"
           [disabled]="disabled" [swiper]="config"
           (indexChange)="onIndexChange($event)"
           (swiperTransitionStart)="onSwiperEvent('transitionStart')"
           (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="background-image: url(/assets/img/Certificaciones/CierreMeca.jpg)"></div>
        </div>
        <div class="swiper-scrollbar" [hidden]="config.scrollbar === true"></div>
        <div class="swiper-pagination" [hidden]="config.pagination === true"></div>
        <div class="swiper-button-prev" [hidden]="config.navigation === true"></div>
        <div class="swiper-button-next" [hidden]="config.navigation === true"></div>
      </div>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <div class="swiper-container"
           [disabled]="disabled" [swiper]="config"
           (indexChange)="onIndexChange($event)"
           (swiperTransitionStart)="onSwiperEvent('transitionStart')"
           (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="background-image: url(/assets/img/Certificaciones/Bachillerato.jpg)"></div>
          <div class="swiper-slide" style="background-image: url(/assets/img/Certificaciones/TituloBachillerato_1.jpg)"></div>
          <div class="swiper-slide" style="background-image: url(/assets/img/Certificaciones/TituloBachillerato_2.jpg)"></div>
          <div class="swiper-slide" style="background-image: url(/assets/img/Certificaciones/Bachillerato_Summa1.jpg)"></div>
          <div class="swiper-slide" style="background-image: url(/assets/img/Certificaciones/Bachillerato_Summa2.jpg)"></div>
        </div>
        <div class="swiper-scrollbar" [hidden]="config.scrollbar === false"></div>
        <div class="swiper-pagination" [hidden]="config.pagination === false"></div>
        <div class="swiper-button-prev" [hidden]="config.navigation === false"></div>
        <div class="swiper-button-next" [hidden]="config.navigation === false"></div>
      </div>
    </div>
  </div>
</div>

<div class="contenedorDoble">
  <div class="box-container-horizontal">
    <div class="content-container-horizontal">
      <div class="swiper-container"
           [disabled]="disabled" [swiper]="config"
           (indexChange)="onIndexChange($event)"
           (swiperTransitionStart)="onSwiperEvent('transitionStart')"
           (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-slide-horizontal" style="background-image: url(/assets/img/Certificaciones/DaleCarniage_Coach.jpg)"></div>
          <div class="swiper-slide swiper-slide-horizontal" style="background-image: url(/assets/img/Certificaciones/DaleCarniage_Student.jpg)"></div>
        </div>
        <div class="swiper-scrollbar" [hidden]="config.scrollbar === false"></div>
        <div class="swiper-pagination" [hidden]="config.pagination === false"></div>
        <div class="swiper-button-prev" [hidden]="config.navigation === false"></div>
        <div class="swiper-button-next" [hidden]="config.navigation === false"></div>
      </div>
    </div>
  </div>
  <div class="box-container-horizontal">
    <div class="content-container-horizontal">
      <div class="swiper-container"
           [disabled]="disabled" [swiper]="config"
           (indexChange)="onIndexChange($event)"
           (swiperTransitionStart)="onSwiperEvent('transitionStart')"
           (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <div class="swiper-wrapper">
          <div class="swiper-slide-horizontal" style="background-image: url(/assets/img/Certificaciones/Scrum.jpg)"></div>
        </div>
        <div class="swiper-scrollbar" [hidden]="config.scrollbar === true"></div>
        <div class="swiper-pagination" [hidden]="config.pagination === true"></div>
        <div class="swiper-button-prev" [hidden]="config.navigation === true"></div>
        <div class="swiper-button-next" [hidden]="config.navigation === true"></div>
      </div>
    </div>
  </div>
</div>

<div class="contenedorDoble">
  <div class="box-container-horizontal">
    <div class="content-container-horizontal">
      <div class="swiper-container"
           [disabled]="disabled" [swiper]="config"
           (indexChange)="onIndexChange($event)"
           (swiperTransitionStart)="onSwiperEvent('transitionStart')"
           (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-slide-horizontal" style="background-image: url(/assets/img/Certificaciones/solid.jpg)"></div>
        </div>
        <div class="swiper-scrollbar" [hidden]="config.scrollbar === true"></div>
        <div class="swiper-pagination" [hidden]="config.pagination === true"></div>
        <div class="swiper-button-prev" [hidden]="config.navigation === true"></div>
        <div class="swiper-button-next" [hidden]="config.navigation === true"></div>
      </div>
    </div>
  </div>
  <div class="box-container-horizontal">
    <div class="content-container-horizontal">
      <div class="swiper-container"
           [disabled]="disabled" [swiper]="config"
           (indexChange)="onIndexChange($event)"
           (swiperTransitionStart)="onSwiperEvent('transitionStart')"
           (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <div class="swiper-wrapper">
          <div class="swiper-slide swiper-slide-horizontal" style="background-image: url(/assets/img/Certificaciones/certificado_LabVIEW2020_1.jpg)"></div>
          <div class="swiper-slide swiper-slide-horizontal" style="background-image: url(/assets/img/Certificaciones/certificado_LabVIEW2020_2.jpg)"></div>
          <div class="swiper-slide swiper-slide-horizontal" style="background-image: url(/assets/img/Certificaciones/certificado_LabVIEW2020_3.jpg)"></div>
        </div>
        <div class="swiper-scrollbar" [hidden]="config.scrollbar === false"></div>
        <div class="swiper-pagination" [hidden]="config.pagination === false"></div>
        <div class="swiper-button-prev" [hidden]="config.navigation === false"></div>
        <div class="swiper-button-next" [hidden]="config.navigation === false"></div>
      </div>
    </div>
  </div>
</div>
