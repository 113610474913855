<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <div class="swiper-container"
           [disabled]="disabled" [swiper]="config"
           (indexChange)="onIndexChange($event)"
           (swiperTransitionStart)="onSwiperEvent('transitionStart')"
           (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="background-image:url(assets/img/Idiomas/Italiano_1.jpg)"></div>
          <div class="swiper-slide" style="background-image:url(assets/img/Idiomas/Italiano_2.jpg)"></div>
          <div class="swiper-slide" style="background-image:url(assets/img/Idiomas/Italiano_3.jpg)"></div>
          <div class="swiper-slide" style="background-image:url(assets/img/Idiomas/Italiano_4.jpg)"></div>
        </div>
        <div class="swiper-scrollbar" [hidden]="config.scrollbar === false"></div>
        <div class="swiper-pagination" [hidden]="config.pagination === false"></div>
        <div class="swiper-button-prev" [hidden]="config.navigation === false"></div>
        <div class="swiper-button-next" [hidden]="config.navigation === false"></div>
      </div>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <div class="swiper-container"
           [disabled]="disabled" [swiper]="config"
           (indexChange)="onIndexChange($event)"
           (swiperTransitionStart)="onSwiperEvent('transitionStart')"
           (swiperTransitionEnd)="onSwiperEvent('transitionEnd')">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="background-image: url(assets/img/Idiomas/Intermedio2.jpg)"></div>
        </div>
        <div class="swiper-scrollbar" [hidden]="config.scrollbar === true"></div>
        <div class="swiper-pagination" [hidden]="config.pagination === true"></div>
        <div class="swiper-button-prev" [hidden]="config.navigation === true"></div>
        <div class="swiper-button-next" [hidden]="config.navigation === true"></div>
      </div>
    </div>
  </div>
</div>
