<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/css.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/javascript.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/html.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
</div>

<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/django.png" />
      <ngb-rating [max]="4" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/Angular.png" />
      <ngb-rating [max]="4" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/php.png" />
      <ngb-rating [max]="4" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
</div>

<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/type.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/csharp.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/net.png" />
      <ngb-rating [max]="3" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
</div>

<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/java.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/python.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/flutter.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
</div>

<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/xamarin.png" />
      <ngb-rating [max]="3" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/solidworks.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/asm.png" />
      <ngb-rating [max]="3" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
</div>

<div class="contenedorDoble">
  <div class="box-container">
    <div class="content-container">
      <img src="/assets/img/Lenguajes/c.png" />
      <ngb-rating [max]="5" [rate]="5" [readonly]="true"></ngb-rating>
    </div>
  </div>
</div>
